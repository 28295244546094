<template>
  <div class="home">
    <PhoneVerification/>
  </div>
</template>

<script>
// @ is an alias to /src
import PhoneVerification from '@/components/PhoneVerification.vue'

export default {
  name: 'UserPhoneVerification',
  components: {
    PhoneVerification
  }
}
</script>
