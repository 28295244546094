<template>
  <div class="cust-cls">
  <el-container class="home-current">
    <h1>Verify your phone</h1>
    <el-header class="steps">
      <el-steps :active="1" align-center>
        <el-step title="Verify your phone" description=""></el-step>
        <el-step title="Register" description=""></el-step>
      </el-steps>
    </el-header>
    <el-main>
      <el-form
        v-if="pinCodeInputShow"
        :model="phoneCode"
        :rules="rulesCode"
        ref="phoneCode"
        label-width="120px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-row class="code">
          <el-col :xs="12" :sm="6" :md="5" :lg="4" :xl="4">
            <el-form-item label="Verification code" prop="secretCode" required>
              <el-input
                placeholder="Enter SMS code"
                v-model="phoneCode.secretCode"
                maxlength="4"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="6" :md="5" :lg="4" :xl="4">
            <el-form-item>
              <el-button
                type="primary"
                round
                v-on:click="verifyPhoneNumber('phoneCode')"
                >Verify</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        :model="userPhone"
        :rules="rulesPhone"
        ref="userPhone"
        label-width="120px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-row class="phone">
          <el-col :xs="8" :sm="3" :md="2" :lg="2" :xl="2">
            <el-form-item label="Country" prop="code" required>
              <el-select
                v-model="userPhone.code"
                filterable
                placeholder="Phone code"
                class="phone-code-list"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="16" :sm="5" :md="5" :lg="3" :xl="3">
            <el-form-item label="Mobile number" prop="phone" required>
              <el-input
                placeholder="Enter number"
                ref="phone"
                v-model.number="userPhone.phone"
                maxlength="15"
                min="5"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
            <el-form-item>
              <el-button
                type="primary"
                round
                :disabled="codeSend"
                @click="submitForm('userPhone')"
                >{{ verifyButtonText }} {{ countDown }}</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
  </div>
</template>

<script>
import axios from "axios";
//import $ from 'jquery'

// import {store} from "@/store";
// import ElementUI from 'element-ui';
const countryCodes = require("country-codes-list");

const myCountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "+{countryCallingCode} {countryNameEn}"
);

const countryCodesObject = countryCodes.customList(
  "countryCode",
  "+{countryCallingCode}"
);

export default {
  name: "PhoneVerification",
  data: function () {
    let validatePhoneEmpty = (rule, value, callback) => {
      if (value.toString().length == 0 && this.existPhone) {
        this.existPhone = false;
        callback(
          new Error(
            "This number is already registered. Please use a different mobile number"
          )
        );
      } else {
        callback();
      }
    };
    let validatePhoneMin = (rule, value, callback) => {
      if (value.toString().length < 5) {
        callback(new Error("Phone number min 5 digits"));
      } else {
        callback();
      }
    };
    let validatePhoneMax = (rule, value, callback) => {
      if (value.toString().length > 15) {
        callback(new Error("Phone number can be max 15 digits"));
      } else {
        callback();
      }
    };
    return {
      userPhone: {
        phone: null,
        code: null,
        partner: null,
        suid : null,
        utm_campaign : null,
        utm_source : null,
        utm_medium : null,
        utm_term : null,
        utm_content : null,
        utm_keyword : null,
        sidi : null,
        sidc : null,
        campaign_id : null,
        channel_id : null,
        utm_device : null,
        utm_creative : null,
        utm_network : null,

      },
      phoneCode: {
        secretCode: "",
      },
      clientIp: null,
      rulesCode: {
        secretCode: [
          {
            required: true,
            message: "Please input your verification code",
            trigger: "blur",
          },
          {
            min: 4,
            max: 4,
            message: "Length must be 4 digits",
            trigger: "blur",
          },
        ],
      },
      rulesPhone: {
        phone: [
          {
            required: true,
            message: " ",
            trigger: "blur",
          },
          { validator: validatePhoneEmpty, trigger: ["blur", "change"] },
          {
            type: "number",
            message: "phone must be a number",
            trigger: ["blur", "change"],
          },
          { validator: validatePhoneMin, trigger: ["blur", "change"] },
          { validator: validatePhoneMax, trigger: ["blur", "change"] },
        ],
        code: [
          {
            required: true,
            message: "Please input your code",
            trigger: "blur",
          },
        ],
      },
      phone: null,
      phone_code: null,
      options: [],
      codeSend: false,
      existPhone: false,
      verifyButtonText: "Verify",
      pinCodeInputShow: false,
      countDown: "",
      labelPosition: "top",
    };
  },
  methods: {
    sendCode: function () {
      //alert("Your verification code is 1234");
      this.countDown = 30;
      this.codeSend = true;
      const x = setInterval(() => {
        this.countDown--;
        if (this.countDown < 1) {
          clearInterval(x);
          this.codeSend = false;
          this.countDown = "";
          return;
        }
      }, 1000);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.countDown = 30;
          this.codeSend = true;
          this.verifyButtonText = "Resend code";
          this.$store.commit("setUserPhone", this.userPhone.phone);
          this.$store.commit("setPartner", this.userPhone.partner);
          this.$store.commit("setSuid", this.userPhone.suid);
          this.$store.commit("setUtmCampaign", this.userPhone.utm_campaign);
          this.$store.commit("setUtmSource", this.userPhone.utm_source);
          this.$store.commit("setUtmMedium", this.userPhone.utm_medium);
          this.$store.commit("setUtmTerm", this.userPhone.utm_term);
          this.$store.commit("setUtmContent", this.userPhone.utm_content);
          this.$store.commit("setUtmKeyword", this.userPhone.utm_keyword);
          this.$store.commit("setSidi", this.userPhone.sidi);
          this.$store.commit("setSidc", this.userPhone.sidc);
          this.$store.commit("setCampaignId", this.userPhone.campaign_id);
          this.$store.commit("setChannelId", this.userPhone.channel_id);
          this.$store.commit("setUtmDevice", this.userPhone.utm_device);
          this.$store.commit("setUtmCreative", this.userPhone.utm_creative);
          this.$store.commit("setUtmNetwork", this.userPhone.utm_network);
          this.$store.commit("setUserPhoneCode", this.userPhone.code);
          axios.post("https://api.finpros.com/api/send_code", {
              phone_number: this.userPhone.phone,
              country_code: this.userPhone.code,
            })
            .then((response) => {
              if (response.data.token) {
                this.$store.commit("setUserToken", response.data.token);
                this.$router.push({ name: "Register" });

                this.successMessage("Phone already  verified","Your phone number is already verified");
              } else if (response.data.crm_registered) {
                this.countDown = "";
                this.userPhone.phone = "";
                this.existPhone = true;
                //$('.el-form-item__error').innerHTML  = "Message is here";
                //this.successMessage('Verified User', 'This number is already registered. Please use a different mobile number')
              } else {
                this.successMessage("Code sent!", response.data.msg);
                this.pinCodeInputShow = true;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          const x = setInterval(() => {
            this.countDown--;
            if (this.countDown < 1) {
              clearInterval(x);
              this.codeSend = false;
              this.countDown = "";
              return;
            }
          }, 1000);
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    verifyPhoneNumber(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post("https://api.finpros.com/api/phone_verify", {
              phone_number: this.userPhone.phone,
              country_code: this.userPhone.code,
              secret_code: this.phoneCode.secretCode,
            })
            .then((response) => {
              if (response.status === 200 && response.data.token) {
                this.$store.commit("setUserToken", response.data.token);
                this.$router.push({ name: "Register" });
                this.successMessage("Phone was  verified","Your phone number was verified successfully");
              }
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage(
                "Code is wrong",
                "Verification Code is incorrect please try again"
              );
            });
        }
      });
    },
    successMessage(mTitle, mMessage) {
      this.$notify.success({
        title: mTitle,
        message: mMessage,
      });
    },
    errorMessage(mTitle, mMessage) {
      this.$notify.error({
        title: mTitle,
        message: mMessage,
      });
    },
    msgPhoneVerified() {
      this.$notify.success({
        title: "Verification code was send",
        message:
          "To number: +" +
          this.userPhone.code +
          " " +
          this.userPhone.phone +
          " was verified",
      });
    },
    makeCountryCodesList(code = false) {
      if (code) {
        this.userPhone.code = countryCodesObject[code];
      } else {
        for (const [key, value] of Object.entries(myCountryCodesObject)) {
        if(value !== '+93 Afghanistan' &&
          value !== '+1684 American Samoa' &&
          value !== '+267 Botswana' &&
          value !== '+236 Central African Republic' &&
          value !== '+235 Chad' &&
          value !== '+53 Cuba' &&
          value !== '+850 North Korea' &&
          value !== '+243 Democratic Republic of the Congo' &&
          value !== '+240 Equatorial Guinea' &&
          value !== '+291 Eritrea' &&
          value !== '+224 Guinea' &&
          value !== '+245 Guinea-Bissau' &&
          value !== '+592 Guyana' &&
          value !== '+98 Iran (Islamic Republic of)' &&
          value !== '+964 Iraq' &&
          value !== "+856 Lao People's Democratic Republic" &&
          value !== '+961 Lebanon' &&
          value !== '+218 Libya' &&
          value !== '+95 Myanmar' &&
          value !== '+1 670 Commonwealth of the Northern Mariana Islands' &&
          value !== '+970 Palestine, State of' &&
          value !== '+375 Belarus' &&
          value !== '+211 South Sudan' &&
          value !== '+249 Sudan' &&
          value !== '+7 Russia' &&
          value !== '+252 Somalia' &&
          value !== '+963 Syrian Arab Republic' &&
          value !== '+992 Tajikistan' &&
          value !== '+868 Trinidad and Tobago' &&
          value !== '+993 Turkmenistan' &&
          value !== '+246 United States Minor Outlying Islands' &&
          value !== '+1340 Virgin Islands (U.S.)' &&
          value !== '+998 Uzbekistan' &&
          value !== '+678 Vanuatu' &&
          value !== '+967 Yemen' &&
          value !== '+263 Zimbabwe'
         )
          this.options.push({
            value: key,
            label: value,
          });
        }
      }
    },
  },
  mounted: function () {
    axios.get(`https://ipinfo.io/json`)
      .then((response) => {
        this.makeCountryCodesList(response.data.country);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(() => {
        this.makeCountryCodesList();
      });
    if (this.$route.query.phone) {
      this.userPhone.phone = +this.$route.query.phone;
    }
    if (this.$route.query.partner) {
      this.userPhone.partner = this.$route.query.partner;
    }
    if (this.$route.query.suid) {
      this.userPhone.suid = this.$route.query.suid;
    }
    if (this.$route.query.utm_campaign) {
      this.userPhone.utm_campaign = this.$route.query.utm_campaign;
    }
    if (this.$route.query.utm_source) {
      this.userPhone.utm_source = this.$route.query.utm_source;
    }
    if (this.$route.query.utm_medium) {
      this.userPhone.utm_medium = this.$route.query.utm_medium;
    }
    if (this.$route.query.utm_term) {
      this.userPhone.utm_term = this.$route.query.utm_term;
    }
    if (this.$route.query.utm_content) {
      this.userPhone.utm_content = this.$route.query.utm_content;
    }
    if (this.$route.query.utm_keyword) {
      this.userPhone.utm_keyword = this.$route.query.utm_keyword;
    }
    if (this.$route.query.sidi) {
      this.userPhone.sidi = this.$route.query.sidi;
    }
    if (this.$route.query.sidc) {
      this.userPhone.sidc = this.$route.query.sidc;
    }
    if (this.$route.query.campaign_id) {
      this.userPhone.campaign_id = this.$route.query.campaign_id;
    }
    if (this.$route.query.channel_id) {
      this.userPhone.channel_id = this.$route.query.channel_id;
    }
    if (this.$route.query.utm_device) {
      this.userPhone.utm_device = this.$route.query.utm_device;
    }
    if (this.$route.query.utm_creative) {
      this.userPhone.utm_creative = this.$route.query.utm_creative;
    }
    if (this.$route.query.utm_network) {
      this.userPhone.utm_network = this.$route.query.utm_network;
    }
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
body, .home-current {
  font-family: 'Poppins',Sans-serif !important;
}
#app .el-step__title.is-process {
  font-weight: 700;
  color: #606266;
  opacity: 0.25;
}
.home-current main.el-main {
  overflow: unset !important;
}
label.el-form-item__label {
  font-family: 'Poppins', Sans-serif !important;
}
button.el-button.el-button--primary.is-round {
  font-family: 'Poppins', Sans-serif !important;
}
.phone-code-list {
  width: 80px;
}
.phone,
.code {
  display: flex;
  align-items: flex-end;
  margin-top: 25px;
  justify-content: center;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-button--primary {
  background: #15a2f1 !important;
  border-color: #15a2f1 !important;
}
.is-disabled {
  background: #c0c4cc !important;
}
.is-disabled {
  color: black;
}
@media only screen and (max-width: 600px) {
  .phone,
  .code {
    margin-top: 5px;
    flex-wrap: wrap;
  }
  h1 {
    font-size: 2rem;
  }
  .code :last-child div {
    display: flex;
    justify-content: flex-end;
  }
  .phone :first-child div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
</style>